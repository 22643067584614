import * as React from 'react';
import { filter, concat, set, includes, pick, clone, cloneDeep } from 'lodash';
import { themes } from '@shapeable/theme';
import { createApplication, WorkstreamTrafficControl, entityConfigs, appSectionConfigs as coreAppSectionConfigs, Icons } from '@shapeable/core';
import { ApplicationLogo, ApplicationLogoInverted } from './components';
import { EntityTypeName } from '@shapeable/types';
const { FeedEntry, Link, SubTopic } = entityConfigs;

const theme = themes.OC_THEME;

if (SubTopic) {
  SubTopic.slug = "resource-type";
  SubTopic.label = "Resource Type";
}

Link.form.fields.author = {
  type: 'text',
};

Link.form.fields.publication = {
  type: 'text',
};

Link.form.fields.edition = {
  type: 'text',
};

Link.form.fields.accessDate = {
  type: 'date',
};

Link.form.fields.footnoteNumber = {
  type: 'int',
};

Link.form.fields.volume = {
  type: 'int',
};

Link.form.fields.year = {
  type: 'int',
};

Link.form.fields.referencePages = {
  type: 'text',
};

delete FeedEntry.form.fields.offeringType;

export const application = createApplication({
  theme,
  options: {
    systemsMapping: 'full',
    shapeLogoLabel: 'oceans',
    shapeLogoIconColor: 'primary',
    shapeLogoIcon: Icons.Platforms.Ecosystems,
  },
  components: {
    Logo: ApplicationLogo,
    LogoInverted: ApplicationLogoInverted,
  },
});


/* ---- Patch Entities definitions afterwards to ensure sub-props are already set --- */

const entities = application.entities;

// -- ensure that traffic control team is not shown in lists
entities.Team.list.filterItems = (items) => filter(items, item => item.slug !== 'traffic-control');

// -- show Traffic controllers
entities.Team.list.postBanner = <WorkstreamTrafficControl />;

delete entities.Challenge;
delete entities.KeyIssue;
delete entities.Trend;
delete entities.Sdg;

delete entities.ChallengeExpertise;
delete entities.KeyIssueExpertise;
delete entities.TrendExpertise;
delete entities.SdgExpertise;

delete entities.Post;
delete entities.Chapter;

delete entities.ImpactStory;
delete entities.Event;
